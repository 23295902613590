<template>
    <div class="auto-reply" v-if="configList && configList.length">
        <div class="tips">您已开启自动感谢，系统将为您自动回复帮助您的每一位爱心人士~</div>

        <div class="content">
            <div class="label">平台已为您推荐以下感谢语</div>
            <div class="list-container">
                <template v-for="(item, index) in configList">
                    <div class="group-item" :key="`${index}-reply`">
                        <div class="cell-title flex">
                            <i class="love-icon"></i>感谢语 {{index+1}}
                        </div>
                        <div class="textarea-ct">

                            <textarea
                                v-if="item.isEdit"
                                class="reply-view"
                                placeholder="和好心人说一句感谢的话吧~"
                                maxlength="40"
                                @input="onInput($event, index)">
                            </textarea>
                            <div
                                v-else
                                class="reply-view view-fixed">{{item.thanking}}
                            </div>

                            <span
                                v-if="!item.isEdit"
                                class="edit-icon"
                                @click="onClick(index)">
                            </span>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <div class="footer">
            <button class="save-btn" @click="onSavaInfo">保存</button>
        </div>
    </div>
</template>

<script>
import { getConfig, postConfig } from './js/api.js';
import { authChecker, shareCtrl } from '@/common/js/mmcModule';

export default {
    name:'AutoReply',
    data () {
        return {
            configList: [],
            projuuid: '',
            isSubmit: false,
            isItemEdit: false,
        }
    },
    created() {
        authChecker.check({ notneedphone: 0 }, (res) => {
            showLoading('加载中');

            this.initConfig();

            shareCtrl.init();
        });
    },
    methods: {
        initConfig() {
            const { projuuid } = this.$route.query;

            getConfig({ uuid: projuuid }).then(res => {
                const { uuid, thanking_list } = res.data;

                this.configList = this.formatList(thanking_list);
                this.projuuid = uuid;

                hideLoading();

            }).catch(() => {
                hideLoading();
            });
        },

        formatList(thanking_list) {
            if (!thanking_list || !thanking_list.length) return;

            thanking_list.map(item => {
                item.isEdit = false
            });

            return thanking_list;
        },


        onClick(index) {
            this.configList[index].isEdit = true;
            this.configList[index].thanking = '';
            this.isItemEdit = true
        },

        onInput(e, index) {
            if (e.target.value) {
                this.configList[index].thanking = e.target.value;
            }
        },

        thankingList() {
            let thanking_list = [];
            let configList = this.configList;

            if (configList.length) {
                configList.map(item => {
                    if ((item.id === 0 || item.isEdit) && item.thanking) {
                        item.thanking = item.thanking.trim();
                        thanking_list.push(item);
                    }
                });
            };
            return thanking_list;
        },

        checkvalidation() {
            // type: -1 默认值  1：未编辑  2: 为空  3 长度校验
            let type = -1;
            let configList = this.configList;
            let isItemEdit = this.isItemEdit;
            console.log(isItemEdit, 'isItemEdit');
            console.log(configList, 'configList');


            for (var i = 0; i < configList.length; i++) {
                // if (!isItemEdit) {
                //     type = 1
                //     break;
                // }
                if (configList[i].isEdit && !configList[i].thanking) {
                    type = 2;
                    break;
                }
                if (configList[i].isEdit && configList[i].thanking.length < 4) {
                    type = 3;
                    break;
                }
            };
            return type;
        },

        onSavaInfo() {
            const checkType = this.checkvalidation()
            console.log(checkType, 'checkType')

            if (checkType === 1){
                alertMsg('感谢语未修改', 1200);
                return;
            }
            if (checkType === 2){
                alertMsg('感谢语不允许为空', 1200);
                return;
            }
            if (checkType === 3){
                alertMsg('感谢语长度不于少4个字符', 1200);
                return;
            }
            const thankingList = this.thankingList();
            const params = {
                thanking_list: thankingList,
                uuid: this.projuuid
            }

            this.isSubmit = true;
            postConfig(params)
            .then(res => {
                alertMsg('保存成功!', 1000);

                setTimeout(() => {
                    this.isSubmit = false;
                    this.$router.push({
                        path: '/fund/project/detail',
                        query: {projuuid: this.projuuid }
                    });
                }, 1000)

            }).catch(() => {
                this.isSubmit = false;
            });
        }
    },
}
</script>

<style lang='less' scoped>
.auto-reply {
    padding: 16px 20px 90px;
    margin-bottom: 20px;
    text-align: left;
    .tips {
        font-size: 14px;
        color: #0099FF;
        padding: 15px 12px;
        background: #F2F9FF;
        border-radius: 8px;
        margin-bottom: 20px;
    }
    .label {
        font-size: 17px;
        color: #333333;
        line-height: 1;
        margin-bottom: 23px;
        font-weight: 600;
    }
    .flex {
       display: flex;
    }

    .group-item {
        margin-bottom: 16px;
        textarea {
            border: none;
        }
    }
    .cell-title {
        font-size: 14px;
        color: #333333;
        margin-bottom: 10px;
        font-weight: 600;

    }
    .love-icon {
        width: 19px;
        height: 19px;
        background: url('./img/love-icon.png') no-repeat center center;
        background-size: contain;
        margin-right: 8px;
    }

    .textarea-ct {
        padding-bottom: 30px;
        background: #F7F7F7;
        position: relative;
        border-radius: 4px;
        height: 78px;

        .reply-view {
            width: 100%;
            height: 100%;
            background: #F7F7F7;
            border-radius: 4px;
            padding: 12px 12px 0;
            text-align: left;
            height: 50px;
        }
        .view-fixed {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
        }

        .edit-icon {
            width: 17px;
            height: 18px;
            background: url('./img/edit-icon.png') no-repeat center center;
            background-size: contain;
            position: absolute;
            right: 10px;
            bottom: 8px;
        }
    }

    .footer {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        background: #fff;
        padding: 12px;
        box-shadow: 0 4px 15px 0 rgba(162, 162, 162, 0.4);
        z-index: 99;

        .save-btn {
            display: block;
            width: 100%;
            background: #089FFF;
            font-size: 20px;
            color: #fff;
            text-align: center;
            border-radius: 4px;
            position: relative;
            padding: 15px 0;
            margin: 0;
            line-height: 1;
            overflow: hidden;
            border: none;
        }
    }
}
</style>
