
import { mmcAjax } from '@/common/js/mmcModule';

/**
 * @param {object} params
 * @param {string} params.project_id 项目uuid
*/

const getConfig = function (params) {
    const url = `${window.APIBasePath}mmc/project/thanking/config/list`;
    const isDebugger = false;
    if (isDebugger) {
        const data = require('../mock/list');
        return new Promise((resolve, rejeect) => {
            resolve(data)
        })
    }

    return mmcAjax({
        method: 'POST',
        url,
        data: params,
        crossDomain: true,
    })
}

const postConfig = function (params) {
    const url = `${window.APIBasePath}mmc/project/thanking/config/edit`;
    return mmcAjax({
        method: 'POST',
        url,
        data: params,
        crossDomain: true,
    })
}

export {
    getConfig,
    postConfig,
}
